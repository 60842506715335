import OurStory1 from "images/ourStory/OurStory_1.webp";
import OurStory2 from "images/ourStory/OurStory_2.webp";
import OurStory3 from "images/ourStory/OurStory_3.webp";
import OurStory4 from "images/ourStory/OurStory_4.webp";
import OurStory5 from "images/ourStory/OurStory_5.webp";
import OurStory6 from "images/ourStory/OurStory_6.webp";
import OurStory7 from "images/ourStory/OurStory_7.webp";

const storyData = [
    {
      date: "September 2021:",
      image: OurStory1,
      blurhash: 'LsKwzpbbtSkC~WV@oLofi^oLM{WB',
      text: "Unveiling our first logo and sketching out interior design ideas for our charming space.Exploring the cozy nooks and corners of our future shop.",
      textFr: "Sortie de notre premier logo et première esquisse de design d'intérieur pour notre future boutique. Visite d'arcades disponibles."
    },
    {
      date: "October 2021:",
      image: OurStory2,
      blurhash: 'LYIq}t4T?c9FITt8t7Rj~p-pIUj]',
      text: "Birth of our company, now known as Le Plongeoir Upcycling Factory. Our mission: to blend secondhand treasures and sewing magic next to a cozy tea room, all while making a positive impact on local economy and environment.",
      textFr: "Naissance de notre entreprise, désormais connue sous le nom de Le Plongeoir Upcycling Factory. Notre mission : mélanger trésors de brocante et magie de la couture à côté d'un salon de thé cosy, tout en ayant un impact positif sur l'économie et l'environnement locaux."
    },
    {
      date: "June 2022:",
      image: OurStory3,
      blurhash: 'LBEL$vJAyso#4.wGIAxCkXt8iw%2',
      text: "After a whirlwind of renovations, we finally got the keys and began decking out our space with our first batch of eclectic furniture finds.",
      textFr: "Après un tourbillon de rénovations, nous avons finalement obtenu les clés et avons commencé à aménager notre espace avec notre premier lot de meubles éclectiques."
    },
    {
      date: "June 2022:",
      image: OurStory4,
      blurhash: 'L1GIDrl.:P0000F}+X0000=iF0_4',
      text: "Taking our first steps into the world of Instagram, sharing snapshots of our journey.",
      textFr: "Faire nos premiers pas dans le monde d'Instagram, partager des instantanés de notre voyage."
    },
    {
      date: "October 2022:",
      image: OurStory5,
      blurhash: 'L6Jao+=_%N?a}*-;NH0K009H~VjE',
      text: "It's party time! We throw open our doors for a dazzling opening celebration, putting the finishing touches on our cozy haven.",
      textFr: "C'est l'heure de la fête! Nous ouvrons nos portes pour une éblouissante célébration d’ouverture, mettant la touche finale à notre havre de paix."
    },
    {
      date: "February 2023:",
      image: OurStory6,
      blurhash: 'LQJk7ZVr%$Wn~pWBD*Rj0eR*Mxs:',
      text: "The final touch to our storefront – custom door stickers that welcome visitors with open arms.",
      textFr: "La touche finale à notre vitrine : des autocollants de porte personnalisés qui accueillent les visiteurs à bras ouverts."
    },
    {
      date: "February 2024:",
      image: OurStory7,
      blurhash: 'L7AmYk~BH=nh01%M.8t7i^%1-:of',
      text: "A fiery milestone reached – 3000 followers on Instagram! 🔥.",
      textFr: "Un cap fougueux franchi : 3000 followers sur Instagram ! 🔥"
    },
  ];

  export {storyData}