import FitlerIcon from 'images/icons/filter.svg';
import ListItem from '../../SharedComponents/List-Item';
import Filter from './Filter';
import Overlay from 'app/SharedComponents/Overlay';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import config from 'config';
import { fetchData } from 'app/services/fetchService';
import { clothingTypeMulti } from 'globalVariables/globalVariables';

const Shop = () => {
    const {i18n, t} = useTranslation();
    const [products, setProducts] = useState([]);
    const [productsTotal, setProductsTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [filterOpened, setFilterState] = useState(false);
    const location = useLocation();
    const [pageTitle, setPageTitle] = useState('')

    const toggleFilter = ()=>{
        setFilterState(!filterOpened);
    }

    useEffect(()=>{
        const searchParams = new URLSearchParams(location.search);
        calculatePageTitle(searchParams.get('clothingType'));
    })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.toString();
    const url = `${config.apiUrl}/products?${query}&page=${page}`;
    fetchData(url)
      .then(data => {
        setProductsTotal(data.count)
        if(page>0){
            setProducts([...products,...data.products]);
        }else{
            setProducts(data.products);
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, page]);

  const calculatePageTitle = (str)=>{
    if(!str){
        setPageTitle("All Products");
        return;
    }
    const current = clothingTypeMulti.find(cloth=>cloth.en === str);
    
    // if(i18n.language === 'en'){
        setPageTitle(current[i18n.language]);
    // }
  }
  
  const nextPage = ()=>{
    setPage(page + 1);
  }

  const setPageToZero= ()=>{
    setPage(0)
  }


    return ( 
        <section id="shop" className="container">
            {/* <p className="shopNav"><span>{t('home')}</span> / <span>{t('shop')}</span> / <span>All Products</span></p> */}
            <h2>{pageTitle}</h2>
            <div className='shopTools'>
                <p className='shop--count'>{productsTotal} {t('items')}</p>
                <p className='shop--filter'  onClick={toggleFilter}><img src={FitlerIcon} alt="Icon" /> Filters</p>
            </div>

            <div className='shop--grid'>

                {
                    products.map(product=>(
                        <ListItem key={product._id} product={product}/>
                    ))
                }

            </div>
            <div className='shopButton'>
                {
                productsTotal > products.length ?
                <button onClick={nextPage}>{t('more')}</button>
                :
                null
            }
            </div>


            {
                filterOpened ? 
                <Overlay styles={['overlayContentFilter']} isOpened={filterOpened} toggleOverlay={toggleFilter}>
                    <Filter setPageToZero={setPageToZero} />
                </Overlay>
                :
                null
            }

        </section>
     );
}
 
export default Shop;