import AppButton from "app/SharedComponents/App_Button";
import InfoExpanderV2 from "app/SharedComponents/InfoExpanderV2";
import { fetchData } from "app/services/fetchService";
import config from "config";
import { clothingTypeMulti, colors, materials, sizes } from "globalVariables/globalVariables";
import multiColorImg from "images/icons/multiColor.svg";
import { useEffect, useRef, useState } from "react";
import deleteIcon from 'images/icons/icon_trash.svg';
import { useNavigate } from "react-router-dom";

const CreateListing = () => {
  const [ loading , setLoading ] = useState(false);
  const [preview, setPreview] = useState([]);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

    const [formControl, setControls] = useState({
        clothingType: "",
        color: "",
        material: "",
        name: "",
        name__french: "",
        brand: "",
        brand__french: "",
        price: "",
        size: "",
        dimensions : [],
        pictures: [],
    })

    const inputChange = (e)=>{
        setControls({...formControl, [e.target.name] : e.target.value})
    }


    const inputDimensionChange = (e, index) => {
      const { name, value } = e.target;
      setControls(prevState => {
          const newDimensions = prevState.dimensions.map((dimension, i) => 
              i === index ? { ...dimension, [name]: value } : dimension
          );
          return { ...prevState, dimensions: newDimensions };
      });
    };

  const addDimension = () => {
      setControls(prevState => ({
          ...prevState,
          dimensions: [...prevState.dimensions, {}]
      }));
  };
    const createListing = (e)=>{
        e.preventDefault();
        sendPostReq();
    }


    const sendPostReq = async () => {
        setLoading(true);
        const url = `${config.apiUrl}/products`;
        const {pictures, ...data} = formControl;
        const accessToken = localStorage.getItem('token');

        const formData = new FormData();
        for (let i = 0; i < formControl.pictures.length; i++) {
          formData.append("pictures", formControl.pictures[i]);
        }
        formData.append("data", JSON.stringify(data))
    
        try {
          
          await fetchData(url, {
            method: 'POST', 
            headers:{
              'Authorization': `Bearer ${accessToken}`,
            },
            body: formData
          })
          .then(res=>{
            alert("Item added!")
            navigate('/admin/itemList')
          })
          .finally(()=>{
            setLoading(false);
          })
        } catch (error) {
          console.error('Error:', error);
        }
      };


      //fileUpload

      const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        const combinedFiles = [...formControl.pictures, ...files]

        setControls((prevState) => ({
            ...prevState,
            pictures: combinedFiles
        })); 

        setPreviewImages(combinedFiles);

      };

      useEffect(()=>{
        console.log("F", formControl.pictures)
      },[formControl])


      const addImage = ()=>{
        fileInputRef.current.click();
      }

      const deleteImg = (i)=>{
        const oldImages = [...formControl.pictures]
        const newImages = oldImages.filter((item,index)=>index!==i);

        setControls((prevState) => ({
          ...prevState,
          pictures: newImages
      }));        

      setPreviewImages(newImages)
      }

      const setPreviewImages = (combinedFiles)=>{
        const filesToLoad = [];
        for (let i = 0; i < combinedFiles.length; i++) {
          let file = combinedFiles[i];
          filesToLoad.push(URL.createObjectURL(file))
        } 
        console.log(filesToLoad);
        setPreview(filesToLoad);
      }



    return ( 
        <div id="createListing" className="container">

            <h2>Add Item</h2>
          
            <img src="a" alt="" />

        <form onSubmit={createListing}>

          <input onChange={inputChange}  type="text" name="name" placeholder="Name*" required/>
          <input onChange={inputChange}  type="text" name="name__french" placeholder="Name (French)*" required/>

          <input onChange={inputChange}  type="text" name="brand" placeholder="Brand*" required/>
          <input onChange={inputChange}  type="text" name="brand__french" placeholder="Brand (French)*" required/>
          <input onChange={inputChange}  type="number" name="price" placeholder="Price*" required/>

            <InfoExpanderV2 title="Type of Clothing" initialState={true}>
                <div className="type__of__clothing">
                  <div>
                      {
                        clothingTypeMulti.slice(1,6).map(clothType=>(
                          <label key={clothType.en} className="radioBtn">
                              <input onChange={inputChange}  type="radio" name="clothingType" value={clothType.en} required />
                              {clothType.en}
                          </label>
                        ))
                      }
                  </div>
                  <div>
                      {
                        clothingTypeMulti.slice(6).map(clothType=>(
                          <label key={clothType.en} className="radioBtn">
                              <input onChange={inputChange}  type="radio" name="clothingType" value={clothType.en} required />
                              {clothType.en}
                          </label>
                        ))
                      }
                  </div>
                </div>
            </InfoExpanderV2>


            <InfoExpanderV2 title="Color" initialState={true}>
            <div className="expanderColor">
              <div>
                {
                  colors.slice(0,4).map(color=>(
                    <label key={color.en} className="radio">
                      <input onChange={inputChange}  type="radio" name="color" value={color.en} required/>
                      <p className={`radio${color.en}`}> {color.en}</p>
                    </label>
                  ))
                }
              </div>

              <div>
                {
                  colors.slice(4,8).map(color=>(
                    <label key={color.en} className="radio">
                      <input onChange={inputChange}  type="radio" name="color" value={color.en} required/>
                      <p className={`radio${color.en}`}> {color.en}</p>
                    </label>
                  ))
                }
              </div>

              <div>
                {
                  colors.slice(8).map(color=>(
                    <label key={color.en} className="radio">
                    <input onChange={inputChange}  type="radio" name="color" value={color.en} required/>
                    <p className={`radio${color.en}`}> {color.en}</p>
                  </label>
                  ))
                }
                <label className="radio">
                  <input onChange={inputChange}  type="radio" name="color" value="MultiColor" required/>
                  <p className="radioMulticolor">
                    <img src={multiColorImg} alt="X" />
                    Multicolor
                  </p>
                </label>
              </div>
            </div>
          </InfoExpanderV2>

          <InfoExpanderV2 title="Size" initialState={true}>
            <div className="expanderSize">
                {
                  sizes.map(size=>(
                    <label key={size} className="bigRadioButton">
                      <input onChange={inputChange}  type="radio" name="size" value={size} required/>
                      <span className="checkmark">{size}</span>
                    </label>
                  ))
                }
            </div>
          </InfoExpanderV2>

            <InfoExpanderV2 title="Material" initialState={true}>
            <div className="expanderMaterial">
              <div>
                {
                  materials.slice(0,5).map(material=>(
                  <label key={material.en} className="radio">
                    <input onChange={inputChange}  type="radio" name="material" value={material.en} required/>
                    <p>{material.en}</p>
                  </label>
                  ))
                }
              </div>

              <div>
              {
                  materials.slice(5).map(material=>(
                  <label key={material.en} className="radio">
                    <input onChange={inputChange}  type="radio" name="material" value={material.en} required/>
                    <p>{material.en}</p>
                  </label>
                  ))
                }
              </div>
            </div>
          </InfoExpanderV2>

          <div className="imageList">
            {
              preview.map((v,index)=>(
                <div key={index} className="imgContainer">
                  <img className="iconDelete" src={deleteIcon} onClick={()=>{deleteImg(index)}}/>
                  <img src={v} alt="" className="mainImg"/>
                </div>
              ))
            }


            <div className="imgAdder" onClick={addImage}>
                      +
                      <input ref={fileInputRef} type="file" name="file" onChange={handleFileChange} multiple />
            </div>
          </div>


          <div className="exactDimensions__wrap">
            <div className="exactDimension__header">
              <p className="exactDimensions__title">Exact Dimentions</p>
              <button type="button" onClick={addDimension}>Add Dimention</button>
            </div>
              {
                formControl.dimensions.map((control, index)=>
                <div key={index} className="exactDimension__cell">
                  <p>Item {index + 1}:</p>
                  <div>
                    <input onChange={(e)=>{inputDimensionChange(e, index)}} name="dimension__name" type="text" placeholder="Name" required/>
                    <input onChange={(e)=>{inputDimensionChange(e, index)}} name="dimension__name_fr" type="text" placeholder="Name (French)" required/>

                    <div className="dimentions">
                      <input onChange={(e)=>{inputDimensionChange(e, index)}} name="dimension__waist" type="number" placeholder="Waist" required/>
                      <input onChange={(e)=>{inputDimensionChange(e, index)}} name="dimension__length" type="number" placeholder="Length" required/>
                      <input onChange={(e)=>{inputDimensionChange(e, index)}} name="dimension__shoulders" type="number" placeholder="Shoulders" required/>
                    </div>
                  </div>
                </div>)
              }
          </div>

          <AppButton loading={loading}>Add Item</AppButton>
        </form>
        </div>
     );
}
 
export default CreateListing;