import Coat from "images/catalogue/coat.webp";
import PANTS from "images/catalogue/pants.webp";
import SKIRT from "images/catalogue/skirts.webp";
import SUIT from "images/catalogue/suits.webp";
import SWEATERS from "images/catalogue/sweaters.webp";
import DRESSES from "images/catalogue/pants.webp";
import SHIRTS from "images/catalogue/shirts.webp";
import SHOES from "images/catalogue/coat.webp";
import TOP from "images/catalogue/tops.webp";
import ACCESSORIES from "images/catalogue/accessories.webp";




const clothingTypeMulti = [
    {
        en: 'ALL',
        fr: 'TOUS LES ARTICLES',
    },
    {
        en: 'COATS',
        fr: 'MANTEAUX',
        img: Coat,
        blurHash: 'LZL3[axu?bt7~WaykCj[RPWBD*ay'
    },
    {
        en: 'PANTS',
        fr: 'PANTALONS',
        img: PANTS,
        blurHash: 'LZL3[axu?bt7~WaykCj[RPWBD*ay'
    },
    {
        en: 'SKIRTS',
        fr: 'JUPES',
        img: SKIRT,
        blurHash: 'LZL3[axu?bt7~WaykCj[RPWBD*ay'
    },
    {
        en: 'SUITS',
        fr: 'ENSEMBLES',
        img: SUIT,
        blurHash: 'LZL3[axu?bt7~WaykCj[RPWBD*ay'
    },
    {
        en: 'SWEATERS',
        fr: 'PULLS',
        img: SWEATERS,
        blurHash: 'LZL3[axu?bt7~WaykCj[RPWBD*ay'
    },
    {
        en: 'DRESSES',
        fr: 'ROBES',
        img: DRESSES,
        blurHash: 'LZL3[axu?bt7~WaykCj[RPWBD*ay'
    },
    {
        en: 'SHIRTS',
        fr: 'CHEMISES',
        img: SHIRTS,
        blurHash: 'LZL3[axu?bt7~WaykCj[RPWBD*ay'
    },
    {
        en: 'SHOES',
        fr: 'CHAUSSURES',
        img: SHOES,
        blurHash: 'LZL3[axu?bt7~WaykCj[RPWBD*ay'
    },
    {
        en: 'TOPS',
        fr: 'HAUTS',
        img: TOP,
        blurHash: 'LZL3[axu?bt7~WaykCj[RPWBD*ay'
    },
    {
        en: 'ACCESSORIES',
        fr: 'ACCESSORIES',
        img: ACCESSORIES,
        blurHash: 'L4KmkPNGT0IA00%MIAD%%%9EIU_4'
    }
]

const colors = [
    {
        en: 'RED',
        fr: 'Rouge',
    },
    {
        en: 'ORANGE',
        fr: 'Orange',
    },
    {
        en: 'YELLOW',
        fr: 'Jaune',
    },
    {
        en: 'GREEN',
        fr: 'Vert',
    },
    {
        en: 'BLUE',
        fr: 'Bleu',
    },

    {
        en: 'VIOLET',
        fr: 'Violet',
    },
    {
        en: 'PINK',
        fr: 'Rose',
    },
    {
        en: 'BLACK',
        fr: 'Noir',
    },
    {
        en: 'WHITE',
        fr: 'Blanc',
    },
    {
        en: 'GREY',
        fr: 'Gris',
    },
    {
        en: 'BROWN',
        fr: 'Brun',
    },
  ];

  const sizes = [
    "XS",
    "S",
    "M",
    "L",
    "XL"
  ]

  const materials = [
    {
        en: 'COTTON',
        fr: 'Coton'
    },
    {
        en: 'LINEN',
        fr: 'Lin'
    },
    {
        en: 'SILK',
        fr: 'Soei'
    },
    {
        en: 'WOOL',
        fr: 'Laine'
    },
    {
        en: 'SYNTHETIC',
        fr: 'Synthétique'
    },
    {
        en: 'LEATHER',
        fr: 'Cuir'
    },
    {
        en: 'FAUX LEATHER',
        fr: 'Faux cuir'
    },
    {
        en: 'SUEDE',
        fr: 'Suède'
    },
    {
        en: 'OTHER MATERIAL',
        fr: 'Autre matériel'
    }
  ];



export {clothingTypeMulti, materials, sizes, colors}