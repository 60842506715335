import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


const List_Item = ({product = {}}) => {
    const { i18n } = useTranslation();
    
    return ( 
    <div className='listItem'>
        <Link to={`item/${product._id}`}>
            <div className='image__wrap'>
                <img src={product.images && product.images[0]} alt="Shop Item" />
            </div>
            <div className='item--info'>
                <p className='itemName'>{i18n.language==="en" ? product.name : product.name__french}</p>
                <p className='itemBrand'>{i18n.language==="en" ? product.brand : product.brand__french}</p>
                <p className='itemPrice'>{product.price} CHF</p>
            </div>
        </Link>
    </div>
     );
}
 
export default List_Item;