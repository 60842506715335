import LaButique from "app/SharedComponents/LaButique";

const La_ButiquePage = () => {
    return ( 
        <>
            <LaButique displayCenterText={false}></LaButique>
        </>
     );
}
 
export default La_ButiquePage;