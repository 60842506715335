import WelcomeImage from 'images/welcome/mainImage.webp';
import WelcomeImageMobile from 'images/welcome/mainImage-mobile.webp';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';



const Welcome = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const shopNow = ()=>{
        navigate('/shop');
    }
    return ( 
        <section id="welcome">
            <picture>
                <source media='(min-width:992px)' srcSet={WelcomeImage}/>
                <img src={WelcomeImageMobile} alt="" />
            </picture>
            <div className='welcome--overaly'>
                <div className='welcome--wrap container'>
                    <div className='welcome--text'>
                        <h1>{t('welcome_header')}</h1>
                        <p>{t('welcome_text')}</p>
                        <button onClick={shopNow}>{t('shop_now')}</button>
                    </div>
                </div>
            </div>
        </section>
     );
}
 
export default Welcome;