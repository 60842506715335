import LaPlongeuse from "./La_Plongeuse ";

const La_PlongeusePage = () => {
    return ( 
        <>
            <LaPlongeuse/>
        </>
     );
}
 
export default La_PlongeusePage;