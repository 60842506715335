import InfoExpanderV2 from "app/SharedComponents/InfoExpanderV2";
import closeImg from "images/icons/close.svg";
import multiColorImg from "images/icons/multiColor.svg";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { materials, sizes, colors } from "globalVariables/globalVariables";
import { useTranslation } from "react-i18next";

const Filter = ({ closeOverlay, setPageToZero }) => {
  const formRef = useRef(null);
  const { i18n, t } = useTranslation();



  let currentFilters = {
    color: [],
    size: [],
    material: []
  }

  const navigate = useNavigate();

  const setQuery = (query) => {
    const newSearchParams = new URLSearchParams(query);
    navigate({ search: newSearchParams.toString() });
  };




  const inputChange = (e)=>{
    const { name, checked, value } = e.target;
    if(checked){
      currentFilters[name].push(value);
    }else{
      currentFilters[name] = currentFilters[name].filter(item => item !== value);
    }
  }

const onShowHandle  = () => {
  setPageToZero();
  setQuery(currentFilters);
  closeOverlay();
};


  return (
    <div id="filterComponent">
      <div className="filter__header">
        <h3>Filters</h3>
        <img onClick={closeOverlay} src={closeImg} alt="X" />
      </div>

      <div className="filterBody">
        <form ref={formRef}>
          <InfoExpanderV2 title="Color" initialState={true}>
            <div className="expanderColor">
                {
                  colors
                  .map(color=>(
                  <label key={color.en} className="checkBox">
                    <input onChange={inputChange} type="checkbox" name="color" value={i18n.language === "en" ? color.en : color.fr} />
                    <p className={`radio${color.en}`}>{i18n.language === "en" ? color.en : color.fr}</p>
                  </label>
                  ))
                }
                <label className="checkBox">
                  <input type="checkbox" name="color" value="MultiColor" />
                  <p className="radioMulticolor">
                    <img src={multiColorImg} alt="X" />
                    Multicolor
                  </p>
                </label>
            </div>
          </InfoExpanderV2>

          <InfoExpanderV2 title="Size" initialState={true}>
            <div className="expanderSize">
              {
                sizes.map(size=>(
                  <label key={size} className="bigRadioButton">
                    <input onChange={inputChange} type="radio" name="size" value={size} />
                    <span className="checkmark">{size}</span>
                </label>
                ))
              }
            </div>
          </InfoExpanderV2>

          <InfoExpanderV2 title="Material" initialState={true}>
            <div className="expanderMaterial">
                <div>
                  {
                    materials.slice(0,5).map(material=>i18n.language === "en" ? material.en : material.fr)
                    .map((material)=>(
                      <label key={material} className="checkBox">
                        <input onChange={inputChange} type="checkbox" name="material" value={material} />
                        <p>{material}</p>
                      </label>
                    ))
                  }
                </div>
                <div>
                  {
                    materials.slice(5).map(material=>i18n.language === "en" ? material.en : material.fr)
                    .map((material)=>(
                      <label key={material} className="checkBox">
                        <input onChange={inputChange} type="checkbox" name="material" value={material} />
                        <p>{material}</p>
                      </label>
                    ))
                  }
                </div>
            </div>
          </InfoExpanderV2>
        </form>
      </div>

      <div className="filterActions">
        <button className="btnTransparent">{t('reset')}</button>
        <button onClick={onShowHandle}>{t('show')}</button>
      </div>
    </div>
  );
};

export default Filter;
