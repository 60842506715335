import ShopItem from "app/mainApp/ShopItemPage/ShopItem";

const ShopItemPage = () => {
    return ( 
        <>
            <ShopItem/>
        </>
     );
}
 
export default ShopItemPage;