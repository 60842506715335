import ImageComponent from 'app/SharedComponents/ImageComponent';
import { clothingTypeMulti } from 'globalVariables/globalVariables';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Catalogue = () => {
    let [catalogueData, setCatalogueData] = useState();
    const { i18n } = useTranslation();
    const navigate = useNavigate();


    useEffect(()=>{
        setCatalogueData(clothingTypeMulti.slice(1));
    },[])

    const handleNavigate = (type)=>{
        navigate(`/shop?clothingType=${type}`);
    }

    return ( 
    <section id="catalogue" className='container'> 
        <h2>Catalogue</h2>

        <div className="catalogue--grid">
            {
                catalogueData && catalogueData.map(item=>(
                    <div key={item.en} className='catalogue--grid--cell' onClick={()=>handleNavigate(item.en)}>
                        <div className='catalogue--image--container'>
                            <ImageComponent src={item.img} blurhash={item.blurHash} />
                        </div>
                        <p>{i18n.language ==="en" ? item.en : item.fr}</p>
                    </div>
                ))
            }
        </div>

    </section>
);
}
 
export default Catalogue;   