import Shop from './Shop';

const ShopPage = () => {
    return ( 
        <>
            <Shop></Shop>
        </>
     );
}
 
export default ShopPage;