import Catalogue from './Catalogue';
import LaButique from '../../SharedComponents/LaButique';
import Map from './Map';
import Welcome from './Welcome';

const HomePage = () => {
    return ( 
    <>
        <Welcome/>
        <Catalogue/>
        <LaButique displayCenterText={true}/>
        <Map/>
    </> );
}
 
export default HomePage;